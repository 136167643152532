<template>
  <div class="">
    <div class="row">
      <!-- Sidebar -->

      <b-col sm="12" md="6">
        <div class="app-calendar overflow-hidden border p-1 card">
          <full-calendar
            ref="refCalendar"
            :options="calendarOptions"
            class="full-calendar"
          />
        </div>
      </b-col>
      <b-col sm="12" md="6" v-if="fetchAttendance.attendances">
        <div class="card p-1">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">First In</th>
                <th scope="col">Last Out</th>
                <th scope="col">Total Work hrs</th>
                <th scope="col">Break Hours</th>
                <th scope="col">Actual Work hrs</th>
                <th scope="col">Shortfall hrs</th>
                <th scope="col">Excess hrs</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    fetchAttendance.attendances.first_in
                      ? fetchAttendance.attendances.first_in
                      : "-"
                  }}
                </td>
                <td>
                  {{
                    fetchAttendance.attendances.last_out
                      ? fetchAttendance.attendances.last_out
                      : "-"
                  }}
                </td>
                <td>
                  {{
                    fetchAttendance.attendances.total_work_hrs
                      ? fetchAttendance.attendances.total_work_hrs
                      : "-"
                  }}
                </td>
                <td>-</td>
                <td>
                  {{
                    fetchAttendance.attendances.actual_work_hrs
                      ? fetchAttendance.attendances.actual_work_hrs
                      : "-"
                  }}
                </td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card p-1" v-if="false">
          <div class="card-title mb-1">Status Details</div>
          <div class="card-text">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Last Out</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Remarks</td>
                  <td>Last Out</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="card p-1"
          v-if="fetchAttendance.attendances.session_1 != undefined"
        >
          <div class="card-title mb-1">Session Details</div>
          <div class="card-text">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Session</th>
                  <th scope="col">Session timing</th>
                  <th scope="col">First In</th>
                  <th scope="col">Last Out</th>
                  <th scope="col">Late In hrs</th>
                  <th scope="col">Early Out hrs</th>
                </tr>
              </thead>
              <tbody v-if="fetchAttendance.attendances.session_1 != undefined">
                <tr>
                  <td>Session 1</td>
                  <td>
                    {{ fetchAttendance.attendances.session_1.session_timing }}
                  </td>
                  <td>{{ fetchAttendance.attendances.session_1.first_in }}</td>
                  <td>{{ fetchAttendance.attendances.session_1.last_out }}</td>
                  <td>
                    {{ fetchAttendance.attendances.session_1.late_in_hrs }}
                  </td>
                  <td>
                    {{ fetchAttendance.attendances.session_1.early_in_hrs }}
                  </td>
                </tr>
                <tr v-if="fetchAttendance.attendances.session_2 != undefined">
                  <td>Session 2</td>
                  <td>
                    {{ fetchAttendance.attendances.session_2.session_timing }}
                  </td>
                  <td>{{ fetchAttendance.attendances.session_2.first_in }}</td>
                  <td>{{ fetchAttendance.attendances.session_2.last_out }}</td>
                  <td>
                    {{ fetchAttendance.attendances.session_2.late_in_hrs }}
                  </td>
                  <td>
                    {{ fetchAttendance.attendances.session_2.early_in_hrs }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card p-1" v-if="fetchAttendance.swipes != undefined">
          <div class="card-title mb-1">Session Swipes</div>
          <div class="card-text">
            <b-list-group
              v-if="fetchAttendance.swipes && fetchAttendance.swipes.length"
            >
              <b-list-group-item
                class="d-flex align-items-center"
                v-for="swipe in fetchAttendance.swipes"
                :key="swipe.hashid"
              >
                <b>Date :</b> {{ swipe.date | formatDate }}, <b>Time :</b>
                {{ swipe.time | formatTime }}, <b>Swipe type :</b>
                {{ swipe.type }}

                <b-img
                  v-if="swipe.user_feeling != 6 && swipe.type != 'Out'"
                  v-bind="{ width: 40, height: 40, class: 'mr-0 ml-auto' }"
                  fluid
                  :src="options[swipe.user_feeling]"
                  alt="mood"
                />
              </b-list-group-item>
            </b-list-group>
            <b-card-text v-else-if="recentSwipes && recentSwipes.length == 0">
              <div class="item error text-center my-2 empty-data">
              <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
              <h4> OH NO!</h4>
              <p class="d-block mb-1">No Records Found!</p>
            </div>
            </b-card-text>
          </div>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { onUnmounted, watch } from "@vue/composition-api";
import attendanceStoreModule from "../attendanceStoreModule";
import useCalendar from "./useCalendar";
import { BImg } from "bootstrap-vue";
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    BImg,
  },
  props: {
    userHashid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(
        CALENDAR_APP_STORE_MODULE_NAME,
        attendanceStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      fetchAttendance,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar(props.userHashid);

    fetchEvents();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      fetchEvents,
      calendarOptions,
      fetchAttendance,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    };
  },
  data() {
    return {
      options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
