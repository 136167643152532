<template>
  <div>
    <!-- links -->
    <b-row>
      <b-col cols="12" md="6" sm="12" class="mb-md-0 mb-2">
        <div v-if="employeeListOptions.length > 1">
          <label>Employee</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="userHashid"
            :options="employeeListOptions"
            class="w-100"
            :reduce="(val) => val.code"
            placeholder="Select Employee.."
            :clearable="false"
          />
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <div class="demo-inline-spacing">
          <b-button
            v-if="$can('regularization_create')"
            variant="info"
            :to="{ name: 'regularize-applications' }"
          >
            My Regularizations
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <AttendanceInfoCardDeck :user-hashid.sync="userHashid" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import AttendanceInfoCardDeck from "./AttendanceInfoCardDeck.vue";

import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    AttendanceInfoCardDeck,
    vSelect,
  },
  data() {
    return {
      employeeListOptions: [],
      userHashid: this.$cookies.get("userData")["hashid"],
    };
  },
  created() {
    this.descendants();
  },
  methods: {
    descendants() {
      this.$store
        .dispatch("app/descendants")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetching descendants failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: "fetching descendants failed.",
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@media (min-width: 768px) {
  .table-responsive,
  .vgt-responsive {
    overflow: auto;
  }
}

.table-responsive,
.vgt-responsive {
  overflow-y: auto !important;
}
</style>
